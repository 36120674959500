@import 'common-assets/styles/variables/color';

.avl-files-list-grid {
  #avl-grid .ag-header-cell .ag-header-active .ag-header-cell-menu-button {
    display: none;
  }
  #avl-grid.ag-theme-alpine .ag-select .ag-picker-field-display {
    text-align: left;
  }
  #avl-grid.ag-theme-alpine .ag-menu.ag-popup-child {
    min-width: 370px;
  }
}

.avl-container {
  width: 95%;
  margin: auto;

  &--header {
    text-align: left;
  }

  &--spinner-root {
    min-height: 25px;
  }

  &--alert {
    position: fixed;
    margin-left: calc(50% - 16px);
    transform: translateX(-50%);
    z-index: 1;
  }

  .no-rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-avls {
      width: 100px;
      height: 100px;
    }
    h1 {
      color: $text-secondary;
    }
    .adjust-your-search {
      width: 265px;
      color: $text-secondary;
    }
  }
}

#avl-grid {
  .ag-root-wrapper {
    border: none !important;
  }
  .ag-header-cell-text {
    color: var(--text-primary, #212121);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ag-row-odd {
    background: #fff;
  }
  .grid-icon,
  .ag-center-cols-container .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.grid-icon {
    margin: 0 5px;
    position: relative;
    top: 5px;
    cursor: pointer;
    font-size: 20px;
  }
  .ag-theme-alpine .grey-sm-txt {
    color: #666;
    margin: 5px 0 20px;
    font-size: 12px;
  }
}
.dd-container {
  margin-bottom: 15px;
  & > div {
    width: 212px;
  }
}

.message-box {
  .MuiOutlinedInput-root {
    height: 140px;
    display: block;
  }
  textarea.MuiInputBase-input {
    height: 115px !important;
  }
}
