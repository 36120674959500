@import '../../../common-assets/styles/variables/color';
.date-field-string {
  width: 100%;
  .MuiButtonBase-root.MuiIconButton-root {
    padding: 3px;
  }
  .MuiFormHelperText-root {
    color: $error-main !important;
  }
  .Mui-error {
    margin: 3px 0 0 0 !important;
  }
}
