@import '../../common-assets/styles/utilities/functions';

.componentTitle {
  width: 100%;
}

.endorsementsTable {
  width: 100%;
  margin: 20px 0px;
}

.endorsementsTableHeader {
  background-color: #f2f5f6;
  border-bottom: 1px solid #d9e0e5;
  font-weight: 600;
  font-size: pxToRem(14);
  padding: pxToRem(12);
}

.endorsementsTableList {
  width: 100%;
}

.popper {
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  z-index: 10;
}

.popperContent {
  width: 400;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.tableRow {
  border-bottom: 1px solid #e0e0e0;
}

.tableCell {
  padding: pxToRem(12);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
