@import '../../../common-assets/styles/utilities/functions';

.property-checkbox-container {
  display: flex;
  .MuiFormControlLabel-root {
    margin-left: 0;
  }
}

.pdf-icon {
  padding: pxToRem(10);
}
.switch-button-container {
  margin-bottom: 8px;
}

.newProperty,
.newParty {
  margin-top: 16px;
}

.lender-form-section .MuiFormHelperText-contained.Mui-error.Mui-required {
  margin-left: 0;
}

.parties-card-container {
  overflow-x: hidden;
}

.savingFailedErrorHeading {
  font-weight: 500;
  font-size: 16px;
}
.savingFailedErrorList {
  padding-left: 24px;
  margin: 0;
  font-size: 14px;
}

.MuiAlert-standardError.snackbar-message {
  .MuiAlert-icon {
    color: #b3150a !important;
  }
}
