@import 'common-assets/styles/variables/color';
@import 'common-assets/styles/utilities/functions';

.sdn-search {
  button {
    margin-right: pxToRem(12);
    margin-top: pxToRem(6);
  }

  :focus {
    outline: $primary-main auto pxToRem(6);
  }
}

.right-btn {
  display: inherit;

  .search-btn {
    margin-right: 0;
  }
}

.search-results {
  position: static;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* typography/body1 */
  font-style: normal;
  font-weight: 400;
  font-size: pxToRem(15);
  line-height: pxToRem(15);
  /* identical to box height, or 150% */
  letter-spacing: 0.15px;
  /* text/primary */
  color: $text-primary;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0;
}

.sdn-search-padding {
  padding: 0;
}

.sdn-search-container .sdn-search-padding .sdn-search-form-header {
  margin: pxToRem(0, 0, 15);
}

.sdn-search-alert {
  margin-top: pxToRem(20);
  z-index: 99;
}

.sdn-search-padding .results-output {
  border-radius: 4px;
  display: block;
  padding: pxToRem(10, 10, 10, 20);
}

.results-output-non-matched {
  background: $success-secondary;
}

.results-output-matched {
  background: $alert-bg;
}

.sdn-search-padding .results-output .sdn-name-result {
  font-style: normal;
  font-size: pxToRem(16);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.sdn-search-padding .sdn-name-result-matched {
  color: $error-dark;
}

.sdn-search-padding .sdn-name-result-non-matched {
  color: $success-dark;
}

.sdn-search-padding .results-output .result-type .sdn-result-icon,
.sdn-search-padding .results-output .result-type .sdn-result-text {
  font-style: normal;
  font-size: pxToRem(14);
  font-weight: 600;
  vertical-align: middle;
  display: inline-block;
  line-height: pxToRem(14);
}

.sdn-result-icon {
  margin: pxToRem(2.5, 0, 0, 2.5);
}

.sdn-search-content {
  padding: pxToRem(0, 24, 64);
}

.sdn-search-content .mb {
  padding-top: 16px;
  margin-top: 0;
}
.search-fields {
  padding: 0;
}
.fields {
  padding-top: 0 !important;
}
