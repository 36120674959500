@import './fonts';
@import './main';

.ghost-white {
  background: $grey-100;
}
.flex,
.MuiDialogActions-root {
  &-sb,
  &.flex-sb {
    display: flex;
    justify-content: space-between;
  }
  &-split {
    display: flex;
    gap: 24px;
    justify-content: space-between;
  }
}
.p,
.MuiDialogActions-root {
  &-16-24,
  &.p-16-24 {
    padding: 16px 24px;
  }
}
.m {
  &-5-0-15 {
    margin: 5px 0 15px;
  }
  &-20-0 {
    margin: 20px 0;
  }
  &-t-0 {
    margin-top: 0;
  }
  &-b-5 {
    margin-bottom: 5px;
  }
  &-r-15 {
    margin-right: 15px !important;
  }
}

.p {
  &-24 {
    padding: 24px;
  }
  &-b-20 {
    padding-bottom: 20px;
  }
}
.w {
  &-50 {
    max-width: calc(50% - 10px);
  }
  &-100 {
    width: 100%;
  }
}
.box-shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.white-text span {
  color: #fff;
  &:hover {
    color: #4f9be3;
    transition: all 0.02s;
  }
}
.MuiPaper-root.MuiAlert-standardSuccess,
.MuiPaper-root.MuiAlert-standardError {
  width: auto;
  margin-top: 24px;
  max-width: 425px;
}
.f {
  &-14 {
    font-size: 14px;
  }
}

.f-16 {
  font-size: 16px;
}
.f-14 {
  font-size: 14px;
}
.fw-500 {
  font-weight: 500;
}
.grey-txt {
  color: #666;
  font-weight: 400;
}

.custom-pf {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  position: relative;
  svg {
    font-size: 24px;
  }
  span {
    background: red;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    position: absolute;
    top: 15px;
    right: -4px;
  }
}