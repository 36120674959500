.addr-container {
  width: 100%;
  h1 {
    font-weight: 500;
  }
  .mb-2 {
    margin-bottom: 25px;
  }
  .mr-1 {
    margin-right: 15px;
  }
}
