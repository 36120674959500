@import '../../../common-assets/styles/variables/color';
@import '../../../common-assets/styles/utilities/mixins';

.autocomplete-search {
  .MuiAutocomplete-popupIndicatorOpen {
    transform: none;
    transition: 0.3s all;
    svg {
      fill: $primary-main;
    }
  }
}
