@import '../../../common-assets/styles/utilities/functions';

.file-information-container {
  position: relative;
  margin-bottom: pxToRem(72);
  /* Toaster*/
  .alert-wrap-standalone {
    position: absolute;
    width: 100%;
    top: 0;
    max-width: 800px;
  }
}
