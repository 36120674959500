@import '../../../../common-assets/styles/variables/color';
@import '../../../../common-assets/styles/utilities/functions';

.party-not-created-container {
  padding: pxToRem(30);
  p {
    margin: 10px 0;
  }
  .avatar {
    height: 40px;
    width: 40px;
    margin: auto;
    fill: $text-disabled;
  }
  img {
    margin-bottom: 24px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
