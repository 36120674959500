@font-face {
  font-family: 'Inter';
  src: url('../../fonts/inter/Inter-Regular.ttf') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/inter/Inter-Medium.ttf') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/inter/Inter-SemiBold.ttf') format('woff2');
  font-weight: 600;
  font-style: normal;
}
