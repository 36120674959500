@import '../../../common-assets/styles/variables/color';
@import '../../../common-assets/styles/utilities/functions';

.checkbox-group-wrap {
  gap: pxToRem(16);
}

.checkbox-group-button.MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
  transition: 0.3s all;

  .MuiTypography-root {
    color: $text-secondary;
  }
  .MuiIconButton-root {
    padding: 4px 4px 4px 8px;
  }
  .MuiFormControlLabel-label {
    padding: 4px 8px 4px 0;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  &.compact {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &.active {
    .MuiTypography-root {
      color: $primary-black;
    }
  }
  &.disabled {
    background-color: $text-input-gray-background;
  }
}

.border.MuiFormControlLabel-root {
  border: 1px solid $grey-300;
  border-radius: 4px;
  &.active {
    border-color: $outlined-resting-border;
    .MuiTypography-root {
      color: $primary-black;
    }
  }
}
