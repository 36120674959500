// FIGMA COLORS
$brand-blue: #013a6f;
$brand-yellow: #ffcc33;
$brand-red: #e62e1f;
$main-background: #ebeff3;

//FORM INPUT COLORS
$text-input-gray-background: #f5f5f5;

//PALETTE COLORS
$grey-100: #f6f9ff;
$grey-300: #c0cbd3;
$grey-500: #fafafa;
$grey-700: #5b6a75;
$grey-800: #687986;
$grey-900: #343c43;

//DISABLED COLORS
$gray-bkg: #e0e0e0;
$gray-content: #bdbdbd;

//BLUE
$blue-100: #d9e2ea;
$blue-800: #294c7e;
$blue-900: #264156;
$blue-700: #1f4376;

//PRIMARY COLORS
$primary-white: #ffff;
$primary-black: #000000;

$text-primary: #212121;
$text-secondary: #666;
$text-disabled: #9e9e9e;

$primary-main: #0074ca;
$primary-light: #4f9be3;
$primary-dark: #265e92;
$primary-contrast: #ffff;

$secondary-200: #d9e0e5;
$secondary-500: #8297a7;

$contained-hover-bg: #003b73;
$outlined-hover-bg: #ebf2f8;
$outlined-resting-border: #7fa9d1;

$error-main: #b3150a;
$error-dark: #b3150a;
$error-light: #f88078;
$error-contained-hover-bg: #ab2f26;
$error-outlined-hover-bg: #fff0ef;
$error-outlined-resting-border: #f9a09a;

$success-main: #4caf50;
$success-secondary: #eff7ee;
$success-dark: #3b873e;
$success-light: #7bc67e;

$grey-border: #c4c4c4;

$alert-content: #621b16;
$alert-bg: #fdecea;

$status-open: #056b2e;
$status-error: #b3150a;
$status-cancelled: #9e9e9e;
$status-closed: #013a6f;
$status-pending: #ad5f00;
$status-void: #4f4178;
$status-reported: #48799c;
