@import '../../../common-assets/styles/utilities/functions';
@import '../../../common-assets/styles/variables/color';

.divider-container {
  .status {
    margin-left: pxToRem(10);
    font-size: pxToRem(13);
    letter-spacing: 0.4px;
    &-cancelled {
      color: $status-cancelled;
    }
    &-closed {
      color: $status-closed;
    }
    &-error {
      color: $status-error;
    }
    &-open {
      color: $status-open;
    }
    &-pending {
      color: $status-pending;
    }
    &-reported {
      color: $status-reported;
    }
    &-void {
      color: $status-void;
    }
    &-cancelled::before,
    &-closed::before,
    &-error::before,
    &-open::before,
    &-pending::before,
    &-reported::before,
    &-void::before {
      content: '';
      margin-right: 8px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      display: inline-block;
    }
    &-cancelled::before {
      background-color: $status-cancelled;
    }
    &-closed::before {
      background-color: $status-closed;
    }
    &-error::before {
      background-color: $status-error;
    }
    &-open::before {
      background-color: $status-open;
    }
    &-pending::before {
      background-color: $status-pending;
    }
    &-reported::before {
      background-color: $status-reported;
    }
    &-void::before {
      background-color: $status-void;
    }
  }
}
