.loginUserId {
  color: #0074ca !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}
.iconContainer {
  display: inline-block;
  width: 25px;
  /* Adjust the width as needed */
  text-align: center;
}
