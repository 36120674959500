@import '../../common-assets/styles/utilities/functions';

.searchFieldsWrap {
  padding-top: 0;
  margin-top: 0;
}

.service-order-content {
  padding: pxToRem(0, 24, 48);
}

.documentContent {
  padding-top: 0;
  margin-top: 5;
}

.otherFieldsWrap {
  margin-top: pxToRem(20);
  margin-right: pxToRem(10);
}

.submitButton {
  margin-top: pxToRem(30);
  padding-left: pxToRem(20);
}

.content {
  font-weight: bold;
}

.serviceOrderWrap {
  padding: pxToRem(0, 12, 30);
}

.serviceOrdersWrap {
  padding: pxToRem(0, 24, 48);
}

.serviceOrderContentWrap {
  height: calc(85vh - 64px);
}

.uploaderWrap {
  padding: 24px 0px 24px 0px;
}

.emailNotification {
  margin-top: pxToRem(20);
  margin-bottom: pxToRem(20);
  margin-left: pxToRem(15);
}

.otherContent {
  margin-top: pxToRem(20);
  margin-bottom: pxToRem(20);
  margin-right: pxToRem(15);
  margin-left: pxToRem(15);
  width: 100%;
}

.otherControls {
  margin-top: pxToRem(20);
  margin-bottom: pxToRem(20);
  width: 50%;
  display: inline-block;
}

.scroll {
  overflow-y: scroll;
  max-height: pxToRem(30);
}

.iconStyles {
  margin-top: 8px;
  margin-left: 8px;
}

.inputField {
  margin-right: pxToRem(10);
}

#tcButton {
  padding-left: 0%;
  padding-right: 0%;
}

.serviceorder-accordion {
  margin-bottom: 16px;
}

.orderContact {
  line-height: 20px;
  span {
    width: 110px;
    display: inline-block;
    margin-right: 20px;
  }
}

.errorMessage {
  color: #f44336;
  font-size: pxToRem(12);
  margin-top: 3px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.66;
}
