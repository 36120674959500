@import '../../../common-assets/styles/variables/color';
@import '../../../common-assets/styles/utilities/functions';

.MuiAgentNetButton {
  &.MuiButton-outlined {
    &.Mui-Error {
      border-color: $error-outlined-resting-border;
      color: $error-dark;
      &:hover {
        background-color: $error-outlined-hover-bg;
      }
    }
  }

  &.MuiButton-contained {
    &.Mui-Error {
      background-color: $error-dark;
      &:hover {
        background-color: $error-contained-hover-bg;
      }
    }
    .MuiAgentNetButton-Spinner {
      color: $primary-white;
    }

    &.Mui-disabled .MuiAgentNetButton-Spinner {
      color: $primary-main;
    }
  }

  &.MuiButton-text {
    &.Mui-Error {
      color: $error-main;
      &:hover {
        color: $error-contained-hover-bg;
      }
    }
  }

  .MuiAgentNetButton-Spinner {
    margin-right: pxToRem(10);
  }
}
