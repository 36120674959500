@import '../../../../common-assets/styles/variables/color';
@import '../../../../common-assets/styles/utilities/functions';

.fulldropdown {
  width: 100%;
  background-color: $text-input-gray-background;
}

#accountNumber {
  background-color: $text-input-gray-background;
}

.MuiInputBase-input {
  &.MuiInput-input {
    &.MuiInputBase-inputAdornedEnd {
      margin-left: pxToRem(10);
    }
  }
}
div .MuiInput-underline {
  &:hover {
    &:not(.Mui-disabled) {
      &:before {
        border-bottom: none;
      }
    }
  }
  &:after {
    border-bottom: none;
  }
}
