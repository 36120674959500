.loading-container {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.center-pdf-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-icon {
  cursor: pointer;
}

.no-results-msg {
  display: block;
  margin: 0px auto;
  padding: 40px;
  h1,
  p {
    display: block;
    text-align: center;
  }
  h1 {
    margin: 16px 0;
  }
  p {
    margin: 0px;
  }
}

.MuiSvgIcon-root {
  &.no-cpls {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
  }
}

.cpl-accordion {
  margin-bottom: 16px;
}
