#AccountReg {
  #b-6 {
    h1 {
      font-weight: 500;
      color: #fff;
    }
  }
  .flex-split {
    flex: 1;
    gap: 16px;
    .split {
      flex-basis: 50%;
      display: flex;
      gap: 16px;
      .MuiFormControl-root {
        width: 100%;
      }
      & > div {
        flex: 1;
      }
    }
    &.j-start {
      justify-content: flex-start;
    }
  }
  .w-100 {
    .MuiFormControl-root {
      width: 100%;
    }
  }
  .outline-brd {
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    padding: 4px 8px;
  }
}

.outline-btn,
.MuiButton-contained.outline-btn {
  color: #0074ca;
  border: 1px solid #0074ca;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 116, 202, 0.15);
    transition: all 0.2s ease-in-out;
  }
}
.j-sb {
  justify-content: space-between;
  display: flex;
}
