@import '../../../common-assets/styles/utilities/functions';

.individual-party-item-container {
  margin: pxToRem(16, 0);
  .individual-party-item:first-child {
    display: flex;
    align-items: center;
    padding-left: pxToRem(10);
  }
  .party-type-selector {
    margin: pxToRem(10, 0);
  }
}
.open-party {
  &:not(:first-child) {
    padding-bottom: pxToRem(16);
  }
  .individual-party-type,
  .spouse-personal-information {
    padding: pxToRem(16, 0);
  }
  .spouse-personal-information {
    padding-bottom: 0;
  }
}

.closed-party .individual-party-type {
  padding-top: pxToRem(16);
  .party-type-selector {
    margin-bottom: 0;
  }
}

.personal-identification-container {
  padding: pxToRem(10, 0);
}
.lender-title {
  padding-left: pxToRem(10);
}

.form-section-container {
  margin-bottom: pxToRem(20);
}

.MuiMenuItem-root[data-option='AgentNetDropdownSelector'] {
  min-height: 35px;
}

.lender-form-section {
  padding-top: pxToRem(8);
}

.savingFailedErrorHeading {
  font-weight: 500;
  font-size: 16px;
}
.savingFailedErrorList {
  padding-left: 24px;
  margin: 0;
  font-size: 14px;
}

.MuiAlert-standardError.snackbar-message {
  .MuiAlert-icon {
    color: #b3150a !important;
  }
}
