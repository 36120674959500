@import '../../../../common-assets/styles/variables/color';
@import '../../../../common-assets/styles/utilities/functions';

.domestic-section-container > .MuiGrid-container > .MuiGrid-item {
  padding-right: pxToRem(15);
}

.check-box-checkbox {
  margin: pxToRem(5, 40);
}

.address-label {
  margin-right: pxToRem(10);
}

.foreign-address-switch {
  padding-bottom: pxToRem(20);
  display: flex;
}

.party-type-form-container {
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: $primary-main;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: $primary-main;
  }
}
