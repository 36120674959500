@import '../variables/typography';
@import '../variables/color';

//FORM INPUTS
@mixin dropdownProperties {
  border: 1px solid $grey-300;
  border-radius: 4px;
  width: 100%;
}

//MEDIA QUERIES
@mixin xs {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 960px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1600px) {
    @content;
  }
}
