@import '../../common-assets/styles/utilities/functions';
@import '../../common-assets/styles/variables/color';

@keyframes quickFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spinner-root {
  min-height: 50px;
  animation: 0.3s ease-out 0s 1 quickFade;
}

.spinner-wrapper {
  display: inline-block;
  text-align: center;
}
.spinner {
  vertical-align: middle;
  margin-right: 10px;
}

.spinner-text {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $primary-main;
}

.spinner-root.fullscreen {
  background: rgba($grey-900, 40%);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100001;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  .spinner-text {
    font-size: pxToRem(14);
  }
  .spinner-wrapper {
    background: $primary-white;
    padding: pxToRem(10);
    border-radius: 4px;
  }
}

.spinner-root.linear {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .spinner-wrapper {
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.spinner-root.progress {
  .progress-percent {
    & > span {
      margin-left: -10px;
    }
  }
}
