@import '../../../common-assets/styles/variables/color';
@import '../../../common-assets/styles/utilities/functions';

.confirmation-dialog-container {
  height: auto;

  .alert-dailog-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: pxToRem(15, 24);
    button {
      padding: 0;
    }
  }
  .modal-btn-container {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $grey-border;
    padding: pxToRem(16, 24);
    background-color: #f6fbfd;
  }
}

.confirmation-dialog-container-with-message {
  .alert-dailog-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: pxToRem(16, 24);
    button {
      padding: 0;
    }
  }
  .close-modal {
    font-size: pxToRem(20);
    cursor: pointer;
  }
  .modal-content {
    padding: pxToRem(16, 24);
    min-height: pxToRem(120);
  }
  #alert-dialog-title {
    font-size: pxToRem(12);
    font-weight: 800;
  }
  #alert-dialog-description {
    margin-bottom: 1em;
  }
  .modal-action-btn-container {
    display: flex;
    justify-content: space-between;
    height: pxToRem(30);
    padding: pxToRem(16, 24);
  }
}
