@import '../../../../common-assets/styles/variables/color';
@import '../../../../common-assets/styles/utilities/mixins';
@import '../../../../common-assets/styles/utilities/functions';

.files--left-nav--wrap {
  width: 90%;
  margin: pxToRem(39, auto);
}

.files--left-nav {
  text-align: left;
  border: none;
  padding: pxToRem(0, 20);
  @include xs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.files--left-nav--item {
  margin-bottom: 20px;
  margin-left: 14px;
  @include xs {
    width: 45%;
  }
}

.files--left-nav--link {
  font-size: pxToRem(14);
  text-decoration: none;
  color: $primary-main;
  white-space: nowrap;
  &.active {
    font-weight: bold;
  }
  &.unimplemented {
    margin-right: 75px;
    color: darkgrey;
  }
}

.files--left-nav--link:hover {
  text-decoration: underline;

  &.unimplemented {
    text-decoration: none;
  }
}

// Needed for tall sidebars like create file
div[data-testid='scrollable-form-drawer'] {
  height: 100%;
  overflow: auto;
}
