@import '../../../common-assets/styles/variables/color';
@import '../../../common-assets/styles/utilities/mixins';

h5[data-testid='drawer-title-hide-drawer-title'] {
  display: none;
}

//FORM DRAWER
div[data-testid='drawer-content'] {
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  MuiDivider-root {
    background-color: $blue-100;
  }
}
