@import '../../common-assets/styles/utilities/functions';

.jacketsWrap {
  padding: pxToRem(0, 24, 48);
}

.standalone {
  text-align: center;
}

.jacketSection {
  width: 100%;
  padding: pxToRem(12);
}
