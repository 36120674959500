@import '../../../common-assets/styles/utilities/mixins';
@import '../../../common-assets/styles/utilities/functions';
@import '../../../common-assets/styles/variables/color';

.alert-wrap-standalone {
  position: absolute;
  width: 100%;
  top: 0;
  max-width: 800px;
}

.alert-wrap-inline {
  position: relative;
  width: 100%;
  box-shadow: none;
  filter: none;
}

.snackbar-wrapper {
  position: relative;
  z-index: 3000;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .snackbar-fixed {
    position: fixed;
    left: auto;
    right: auto;
    top: auto;
    transform: translateY(-650%);
    @include xs {
      transform: translateY(-100vh);
    }
    &.snackbar-disable-transform {
      transform: none;
    }
  }
}

.snackbar-messenger-wrapper {
  .snackbar-message {
    margin-bottom: pxToRem(16);
  }
}

.sticky-z-index {
  z-index: 1199 !important;
}
