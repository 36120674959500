@import '../../../common-assets/styles/variables/color';
@import '../../../common-assets/styles/utilities/mixins';

.state-input-field {
  .MuiAutocomplete-endAdornment button.MuiButtonBase-root {
    margin-right: 0;
  }
  .MuiAutocomplete-endAdornment {
    right: 0;
  }
}
