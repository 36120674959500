@import '../../../../common-assets/styles/utilities/functions';

.add-party-form-container {
  margin: pxToRem(15, 0);

  .marital-status-selector-container {
    margin-bottom: pxToRem(15);
  }
  .MuiAlert-standardError {
    width: 100%;
    margin: pxToRem(10);
  }
}

.MuiDivider-root {
  &.makeStyles-dividerInset-20 {
    &.MuiDivider-inset:first-child {
      width: 86%;
      margin-bottom: pxToRem(20);
    }
  }
}
.divider-container .MuiDivider-inset {
  margin-left: 0;
}

.align-error {
  margin-bottom: pxToRem(10);
}
