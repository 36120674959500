@import 'common-assets/styles/variables/color';
@import 'common-assets/styles/utilities/functions';

.policy-search-container {
  border-radius: pxToRem(2.5);
  .MuiIconButton-root {
    margin-bottom: pxToRem(5);
  }
}

.policy-search-container {
  margin: pxToRem(0, 0, 15);
}

.policy-search-content {
  padding: pxToRem(0, 24, 64);
}

.policy-search-content .mb {
  padding-top: 16px;
  margin-top: 0;
}

.history-grid-container {
  height: 65vh;
  display: flex;
  flex-direction: column;
}

.history-grid {
  height: 100%;
  width: 100%;
}

.history-result {
  padding: pxToRem(0, 24, 18);
}

.no-results-msg {
  display: block;
  margin: 0px auto;
  padding: 40px;
  text-align: center;

  h3,
  p {
    display: block;
    text-align: center;
  }

  h3 {
    margin: 16px 0;
  }

  p {
    margin: 0px;
  }
}
