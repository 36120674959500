.CPLEditFormContainer {
  margin-bottom: pxToRem(15);

  .CPAEditDetails {
    padding: pxToRem(12, 0, 14, 0);
  }

  .additional-parties {
    margin-bottom: pxToRem(84);
  }
}

.CPL-buttons {
  padding-bottom: pxToRem(5);

  .save-cancel-grp {
    text-align: right;
  }

  .create-cpl-btn {
    margin-left: 15px;
  }
}
